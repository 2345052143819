import AssociationsUser, { ASSOCIATION_TYPES } from 'components/Resources/AssociationsUser'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import { ERROR_URL, NOT_FOUND_URL, PROVIDERS_VIEW_URL, RESOURCES_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useProviders from 'hooks/useProviders'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { DocumentType, ProviderForm, ProviderPost } from 'models/Provider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'
import ProviderInputs from './ProviderInputs'

const ProviderEdit = () => {
	const { providerId } = useParams<{ providerId: string }>()
	const providerIdNumber = parseInt(providerId ?? emptyString) || undefined
	const {
		provider,
		loading,
		error,
		updateProvider,
		deleteAssociationsByProvider,
		resetAndSetAssociations
	} = useProviders(providerIdNumber)
	const [associationUserIds, setAssociationUserIds] = useState<number[]>([])
	const [isProviderLoaded, setIsProviderLoaded] = useState(false)
	const configStore = useConfigStore()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const formik = useFormik<ProviderForm>({
		initialValues: {
			name: emptyString,
			fiscalName: emptyString,
			documentTypeId: null,
			document: emptyString,
			provinceId: null,
			cityId: null,
			address: emptyString,
			postalCode: emptyString,
			isActive: false,
			isInternational: false
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('provider.name') }))
		}),
		onSubmit: async (values) => {
			if (!providerId) return

			const provinceCode = values.provinceId
				? configStore.provinces.byId[values.provinceId]?.code
				: emptyString
			const cityCode = values.cityId
				? configStore.municipalities.byId[values.cityId]?.municipalityCode
				: emptyString
			const documentType = values.documentTypeId
				? configStore.documentTypes.byId[values.documentTypeId]?.name
				: DocumentType.CIF

			const provider: ProviderPost = {
				name: values.name,
				fiscalName: values.fiscalName,
				documentType: documentType,
				document: values.document,
				province: provinceCode,
				city: cityCode,
				address: values.address,
				postalCode: values.postalCode,
				isActive: values.isActive,
				isInternational: values.isInternational
			}
			try {
				const providerUpdated = await updateProvider({
					provider,
					providerId: parseInt(providerId)
				})

				if (associationUserIds.length) {
					await resetAndSetAssociations(providerUpdated.id, associationUserIds)
				} else {
					await deleteAssociationsByProvider(providerUpdated.id)
				}
				toast.success(t('provider.success_update'))
				navigate(`${PROVIDERS_VIEW_URL}/${providerUpdated.id}`)
			} catch (error) {
				console.error({ error })
				toast.error(t('provider.error_update'))
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	useEffect(() => {
		if (!provider || isProviderLoaded) return

		const documentTypeId = configStore.getDocumentTypeByClass(provider.documentType)?.id
		const provinceId = configStore.getProvinceByCode(provider.province)?.id
		const cityId = configStore.getMunicipalityByProvinceCodeAndMunicipalityCode(
			provider.province,
			provider.city
		)?.id
		formik.setValues({
			name: provider.name,
			fiscalName: provider.fiscalName,
			documentTypeId,
			document: provider.document,
			provinceId,
			cityId,
			address: provider.address,
			postalCode: provider.postalCode,
			isActive: provider.isActive,
			isInternational: provider.isInternational
		})
		setIsProviderLoaded(true)
	}, [provider, isProviderLoaded])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })
	if (!provider || !provider.isVisible) {
		navigate(NOT_FOUND_URL)
		return null
	}

	return (
		<FormLayout
			title={t('provider.edit_title')}
			leftButton={<ButtonBack url={`${PROVIDERS_VIEW_URL}/${providerId}`} />}
			rightButton={
				<ButtonComponent type='submit' onClick={handleSubmit} variant='only-text'>
					{t('general.save')}
				</ButtonComponent>
			}>
			<ProviderInputs formik={formik} setFieldRef={setFieldRef} />
			<FormSectionLayout title={t('provider.associate_user')}>
				<AssociationsUser
					type={ASSOCIATION_TYPES.PROVIDER}
					resource={provider}
					onChangeAssociations={(userIds) => setAssociationUserIds(userIds)}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default ProviderEdit
