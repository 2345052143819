import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import useProviders from 'hooks/useProviders'
import { User } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	user: User
	onChangeAssociations: (userIds: number[]) => void
}

const AssociationsProvider: FC<Props> = (props) => {
	const { user, onChangeAssociations } = props

	const { providers, getAssociationsByUserId, loading } = useProviders()
	const [selectedProviders, setSelectedProviders] = useState(new Set<number>())
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])

	const { t } = useTranslation()

	useEffect(() => {
		getAssociations(user.id)
	}, [providers])

	const getAssociations = async (userId: number) => {
		const associations = getAssociationsByUserId(userId)
		const providerIds = associations.map((association) => association.providerId)

		setSelectedProviders(new Set(providerIds))
	}

	const formatAssociations = (): AssociationOption[] => {
		const newAssociations: AssociationOption[] = providers
			.filter((provider) => provider.isActive && provider.isVisible)
			.map((provider) => {
				const isChecked = selectedProviders.has(provider.id)
				return {
					id: provider.id,
					name: provider.name,
					isSelected: isChecked
				}
			})
		return newAssociations
	}

	const handleSelection = (selected: AssociationOption[]) => {
		const newSelectedUsers = new Set<number>()
		selected.forEach((association) => {
			if (association.isSelected) {
				newSelectedUsers.add(association.id)
			}
		})
		setSelectedProviders(newSelectedUsers)
		onChangeAssociations(Array.from(newSelectedUsers))
	}

	useEffect(() => {
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		onChangeAssociations(Array.from(selectedProviders))
	}, [selectedProviders])

	return (
		<AssociationList
			associations={associationsOptions}
			title={t('provider_association.association_list_title')}
			addButtonText={t('provider_association.association_list_button')}
			modalContent={{
				title: t('provider_association.association_list_modal_title'),
				subtitle: t('provider_association.association_list_modal_subtitle'),
				description: t('provider_association.association_list_modal_description')
			}}
			handleSelection={handleSelection}
			isLoading={loading}
		/>
	)
}

export default AssociationsProvider
