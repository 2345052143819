import CheckboxInput from 'components/elements/Input/CheckboxInput'
import Input from 'components/elements/Input/Input'
import InputDate from 'components/elements/Input/InputDate'
import SelectMunicipalityOptions from 'components/elements/SelectOptions/SelectMunicipalityOptions'
import SelectProvinceOptions from 'components/elements/SelectOptions/SelectProvinceOptions'
import StepDetailsInputs from 'components/Transfer/StepInputs/StepDetailsInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useCompany from 'hooks/useCompany'
import { useCreateTransfer } from 'hooks/useCreateTransfer'
import useFomento from 'hooks/useFomento'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useToast from 'hooks/useToast'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import {
	AllStepFormsWithFomentoForm,
	FomentoPost,
	RouteTypeName,
	TransferPost
} from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import { getFormikError } from 'utils/formikUtils'
import * as Yup from 'yup'
import StepAddressesInputs from './StepInputs/StepAddressesInputs'
import StepBookingDateInputs from './StepInputs/StepBookingDateInputs'
import StepClientInputs from './StepInputs/StepClientInputs'
import StepExtrasInputs from './StepInputs/StepExtrasInputs'
import StepPricesInputs from './StepInputs/StepPricesInputs'
import './Steps/StepRoute.scss'
import './TransferDetails.scss'

type RouteParams = {
	transferId: RouteTypeName
}

const TransferCreateNoSteps = () => {
	const { transferId: routeTypeName } = useParams<RouteParams>()

	const [hasShowDistant, setHasShowDistant] = useState<boolean>(false)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const updateActualTransferCreation = useTransfersStore(
		(state) => state.updateActualTransferCreation
	)
	const { actualTransferCreation } = useTransfersStore()
	const transferState = useTransfersStore()
	const { createTransfer } = useCreateTransfer()
	const fomentoState = useFomentoStore()
	const { company } = useCompany()
	const {
		getProvinceCode,
		getMunicipalityCode,
		getProvinceByCodeHelper,
		getMunicipalityByProvinceCodeAndMunicipalityCodeHelper
	} = useFomento()

	const { showBackendErrorToast } = useToast()

	useEffect(() => {
		if (!fomentoState.actualFomentoCreation || !company) return
		const provinceOriginId = fomentoState.actualFomentoCreation.provinceOrigin
			? getProvinceByCodeHelper(fomentoState.actualFomentoCreation.provinceOrigin)
			: getProvinceByCodeHelper(company.province)

		formik.setFieldValue('provinceOriginId', provinceOriginId)
		formik.setFieldValue(
			'municipalityOriginId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoState.actualFomentoCreation.provinceOrigin,
				fomentoState.actualFomentoCreation.municipalityOrigin
			)
		)

		const provinceDestinationId = fomentoState.actualFomentoCreation.provinceDestination
			? getProvinceByCodeHelper(fomentoState.actualFomentoCreation.provinceDestination)
			: getProvinceByCodeHelper(company.province)

		formik.setFieldValue('provinceDestinationId', provinceDestinationId)

		formik.setFieldValue(
			'municipalityDestinationId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoState.actualFomentoCreation.provinceDestination,
				fomentoState.actualFomentoCreation.municipalityDestination
			)
		)

		formik.setFieldValue(
			'provinceDistantId',
			getProvinceByCodeHelper(fomentoState.actualFomentoCreation.provinceDistant)
		)

		formik.setFieldValue(
			'municipalityDistantId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoState.actualFomentoCreation.provinceDistant,
				fomentoState.actualFomentoCreation.municipalityDistant
			)
		)
		formik.setFieldValue('destinationFreely', fomentoState.actualFomentoCreation.destinationFreely)
		formik.setFieldValue('addressDistant', fomentoState.actualFomentoCreation.addressDistant)
	}, [fomentoState.actualFomentoCreation, company])

	useEffect(() => {
		if (!transferState.actualTransferCreation) return
		formik.setFieldValue('finishedAt', transferState.actualTransferCreation.date)
	}, [transferState.actualTransferCreation])

	const hasToShowDistante = () => {
		if (!formik.values['municipalityOriginId'] || !formik.values['municipalityDestinationId']) {
			return false
		}
		return formik.values['municipalityOriginId'] === formik.values['municipalityDestinationId']
	}

	const formik = useFormik<AllStepFormsWithFomentoForm>({
		initialValues: {
			driverId: null,
			providerId: null,
			vehicleId: null,
			passengers: 1,
			date: undefined,
			time: undefined,
			flight: emptyString,
			originAddress: emptyString,
			destinationAddress: emptyString,
			price: null,
			commission: null,
			toll: null,
			isPaid: false,
			clientName: emptyString,
			email: emptyString,
			phone: emptyString,
			babySeats: 0,
			boosterSeats: 0,
			maxicosiSeats: 0,
			observations: emptyString,
			routeTypeId: Number(routeTypeName),

			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		validationSchema: Yup.object().shape({
			driverId: Yup.number().required(t('errors.required_m', { field: t('transfer.driver') })),
			providerId: Yup.number().required(t('errors.required_m', { field: t('transfer.provider') })),
			vehicleId: Yup.number().nullable(),
			passengers: Yup.number().required(
				t('errors.required_m', { field: t('transfer.passengers') })
			),
			date: Yup.string().required(t('errors.required_m', { field: t('transfer.date') })),
			time: Yup.string().required(t('errors.required_m', { field: t('transfer.time') })),
			originAddress: Yup.string().required(t('errors.required_m', { field: t('transfer.origin') })),
			destinationAddress: Yup.string().required(
				t('errors.required_m', { field: t('transfer.destination') })
			),
			price: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			commission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			toll: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			isPaid: Yup.boolean(),
			clientName: Yup.string().required(
				t('errors.required_m', { field: t('transfer.clientName') })
			),
			email: Yup.string().email(t('errors.mail_not_valid')).nullable()
		}),
		onSubmit: async (values) => {
			try {
				if (!values.driverId || !values.providerId) return
				if (!values.date) return
				const transferPost: Partial<TransferPost> = {
					driverId: values.driverId,
					providerId: values.providerId,
					vehicleId: values.vehicleId ?? undefined,
					passengers: values.passengers,
					date: dateToSQL(values.date),
					time: values.time,
					originAddress: values.originAddress,
					destinationAddress: values.destinationAddress,
					price: values.price ? parseFloat(values.price.toString()) : 0,
					commission: values.commission ? parseFloat(values.commission.toString()) : 0,
					toll: values.toll ? parseFloat(values.toll.toString()) : 0,
					isPaid: values.isPaid,
					clientName: values.clientName,
					email: values.email,
					phone: values.phone,
					babySeats: values.babySeats,
					boosterSeats: values.boosterSeats,
					maxicosiSeats: values.maxicosiSeats,
					observations: values.observations,
					route: routeTypeName
				}
				if (actualTransferCreation.route === RouteTypeName.arrival)
					transferPost.flight = values.flight

				updateActualTransferCreation(transferPost)
				const transferCreated = await createTransfer(transferPost)
				const isReadyToFomento = fomentoState.isReadyToSaveInFomento()
				if (isReadyToFomento && transferCreated.id) {
					const fomentoForm = fomentoState.actualFomentoCreation
					fomentoState.createFomento(fomentoForm, transferCreated.id)
				}
				const fomentoForm: FomentoPost = {
					provinceOrigin: getProvinceCode(values.provinceOriginId),
					municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
					provinceDestination: getProvinceCode(values.provinceDestinationId),
					municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
					provinceDistant: getProvinceCode(values.provinceDistantId),
					municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
					destinationFreely: values.destinationFreely,
					addressDistant: values.addressDistant,
					finishedAt: values.finishedAt ? values.finishedAt : undefined
				}
				fomentoState.setActualFomentoCreation(fomentoForm)
				navigate(
					`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.END.toLocaleLowerCase()}/${
						transferCreated.id
					}`
				)
			} catch (error) {
				console.error(error)
				showBackendErrorToast(error)
			}
		}
	})

	useEffect(() => {
		const hasShow = hasToShowDistante()
		setHasShowDistant(hasShow)
	}, [
		formik.values['municipalityOriginId'],
		formik.values['municipalityDestinationId'],
		formik.values['provinceOriginId'],
		formik.values['provinceDestinationId']
	])

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	return (
		<FormTransferLayout onSubmit={handleSubmit} step={2}>
			<FormSectionLayout title={t('transfer.create_steps.step_details.h1')} noDivider>
				<StepDetailsInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v1')} noDivider>
				<StepBookingDateInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v2')} noDivider>
				<StepAddressesInputs
					formik={formik}
					route={actualTransferCreation.route}
					setFieldRef={setFieldRef}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
				<InputDate
					id='finishedAt'
					label={t('transfer.create_steps.step_fomento.selectors.finishedAt.label')}
					value={formik.values['finishedAt'] ? new Date(formik.values['finishedAt']) : undefined}
					onSelect={(date) => formik.setFieldValue('finishedAt', dateToSQL(date))}
					error={getFormikError(formik, 'finishedAt')}
				/>
				<CheckboxInput
					label={t('transfer.create_steps.step_fomento.free_text')}
					id='destinationFreely'
					onChange={(e) => formik.setFieldValue('destinationFreely', e.target.checked)}
					checked={formik.values['destinationFreely']}
					styles={{ margin: '1rem 0' }}
				/>
				<SelectProvinceOptions
					onSelect={(provinceId) => {
						if (provinceId !== formik.values['provinceOriginId']) {
							formik.setFieldValue('provinceOriginId', provinceId)
							formik.setFieldValue('municipalityOriginId', null)
						}
					}}
					id='provinceOriginId'
					selectedId={formik.values['provinceOriginId']}
					error={getFormikError(formik, 'provinceOriginId')}
					label={t('transfer.create_steps.step_fomento.selectors.province.label.origin')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.province.placeholders.origin'
					)}
				/>
				<SelectMunicipalityOptions
					onSelect={(municipalityId) =>
						formik.setFieldValue('municipalityOriginId', municipalityId)
					}
					id='municipalityOriginId'
					selectedId={formik.values['municipalityOriginId']}
					provinceSelected={getProvinceCode(formik.values['provinceOriginId'])}
					error={getFormikError(formik, 'municipalityOriginId')}
					label={t('transfer.create_steps.step_fomento.selectors.municipality.label.origin')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.municipality.placeholders.origin'
					)}
				/>
				<SelectProvinceOptions
					onSelect={(provinceId) => {
						if (provinceId !== formik.values['provinceDestinationId']) {
							formik.setFieldValue('provinceDestinationId', provinceId)
							formik.setFieldValue('municipalityDestinationId', null)
						}
					}}
					id='provinceDestinationId'
					selectedId={formik.values['provinceDestinationId']}
					error={getFormikError(formik, 'provinceDestinationId')}
					label={t('transfer.create_steps.step_fomento.selectors.province.label.destination')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.province.placeholders.destination'
					)}
					isLabelOptional={formik.values['destinationFreely']}
				/>
				<SelectMunicipalityOptions
					onSelect={(municipalityId) =>
						formik.setFieldValue('municipalityDestinationId', municipalityId)
					}
					id='municipalityDestinationId'
					selectedId={formik.values['municipalityDestinationId']}
					provinceSelected={getProvinceCode(formik.values['provinceDestinationId'])}
					error={getFormikError(formik, 'municipalityDestinationId')}
					label={t('transfer.create_steps.step_fomento.selectors.municipality.label.destination')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.municipality.placeholders.destination'
					)}
					isLabelOptional={formik.values['destinationFreely']}
				/>
				{hasShowDistant && (
					<>
						<p>{t('transfer.create_steps.step_fomento.p')}</p>
						<SelectProvinceOptions
							onSelect={(provinceId) => {
								if (provinceId !== formik.values['provinceDistantId']) {
									formik.setFieldValue('provinceDistantId', provinceId)
									formik.setFieldValue('municipalityDistantId', null)
								}
							}}
							id='provinceDistantId'
							selectedId={formik.values['provinceDistantId']}
							error={getFormikError(formik, 'provinceDistantId')}
							label={t('transfer.create_steps.step_fomento.selectors.province.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.province.placeholders.afar'
							)}
						/>
						<SelectMunicipalityOptions
							onSelect={(municipalityId) =>
								formik.setFieldValue('municipalityDistantId', municipalityId)
							}
							id='municipalityDistantId'
							selectedId={formik.values['municipalityDistantId']}
							provinceSelected={getProvinceCode(formik.values['provinceDistantId'])}
							error={getFormikError(formik, 'municipalityDistantId')}
							label={t('transfer.create_steps.step_fomento.selectors.municipality.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.municipality.placeholders.afar'
							)}
						/>
						<Input
							type='text'
							label={t('transfer.create_steps.step_fomento.selectors.address.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.address.placeholders.afar'
							)}
							id='addressDistant'
							onChange={(e) => formik.setFieldValue('addressDistant', e.target.value)}
							className={formik.values['addressDistant'] ? 'selected' : 'not-selected'}
							valueSelected={formik.values['addressDistant']}
							error={getFormikError(formik, 'addressDistant')}
						/>
					</>
				)}
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_prices.h1')} noDivider>
				<StepPricesInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_client.h1')}>
				<StepClientInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
			<FormSectionLayout
				title={`${t('transfer.create_steps.step_extras.h1')} ${t('general.optional')}`}>
				<StepExtrasInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default TransferCreateNoSteps
