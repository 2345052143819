import { useEffect } from 'react'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useFriendsStore } from 'stores/useFriendsStore'
import { useInvoicesStore } from 'stores/useInvoicesStore'
import { useTransfersShareStore } from 'stores/useTransfersShareStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { useUserStore } from 'stores/useUserStore'

const LogOut = () => {
	const logOut = useUserStore((state) => state.logOut)
	const transfersState = useTransfersStore()
	const userState = useUserStore()
	const transferShareState = useTransfersShareStore()
	const invoicesState = useInvoicesStore()
	const friendsState = useFriendsStore()
	const fomentoState = useFomentoStore()

	useEffect(() => {
		transfersState.resetAllTransferInfo()
		userState.resetUsers()
		transferShareState.resetAllInfo()
		invoicesState.resetInvoices()
		friendsState.resetAllFriendsInfo()
		fomentoState.resetFomentoAllFomentoData()
		logOut()
	}, [logOut])

	return null
}

export default LogOut
