import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { userQueryKey, usersQueryKey } from 'constants/reactQueryKeys'
import { ChangeMyPasswordForm, User, UserForm } from 'models/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	changeMyPasswordService,
	createUserByTypeService,
	fetchUsersService,
	getUserByIdService,
	updateUserService
} from 'services/user'

const useUsers = (userId?: number) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET SINGLE USER
	const {
		data: user,
		isLoading: isLoadingUser,
		error: userError,
		isError: isErrorUser
	} = useQuery({
		queryKey: [userQueryKey, userId],
		queryFn: () => getUserByIdService(userId!),
		enabled: !!userId,
		// staleTime: 1000 * 60 * 60,
		refetchOnWindowFocus: true
	})

	// GET ALL USERS
	const {
		data: users,
		isLoading: isLoadingUsers,
		error: usersError,
		isError: isErrorUsers
	} = useQuery({
		queryKey: [usersQueryKey],
		queryFn: fetchUsersService,
		enabled: !userId,
		//staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorUsers && usersError) {
			setErrorMessage(t('user.error_load_users'))
		}
		if (isErrorUser && userError) {
			setErrorMessage(t('user.error_load_user'))
		}
	}, [isErrorUsers, usersError, isErrorUser, userError, t])

	// Invalidate Queries
	const invalidateUserQueries = (userId?: number) => {
		if (userId) {
			queryClient.invalidateQueries({
				queryKey: [userQueryKey, userId]
			})
		}
		queryClient.invalidateQueries({
			queryKey: [usersQueryKey]
		})
	}

	// CREATE USER
	const { mutateAsync: createUser } = useMutation({
		mutationFn: createUserByTypeService,
		onSuccess: () => {
			invalidateUserQueries()
		},
		onError: (error: unknown) => {
			setErrorMessage(t('user.error_create'))
			console.error('Error creando usuario:', error)
		}
	})

	// UPDATE USER BY ID
	const { mutateAsync: updateUser } = useMutation({
		mutationFn: (data: { user: Partial<UserForm>; userId: number }) =>
			updateUserService(data.user, data.userId),
		onSuccess: (_data, variables) => {
			invalidateUserQueries(variables.userId)
		},
		onError: (error: unknown) => {
			setErrorMessage(t('user.error_update'))
			console.error('Error actualizando usuario:', error)
		}
	})

	const changeMyPassword = async (newPassword: ChangeMyPasswordForm) => {
		return await changeMyPasswordService(newPassword)
	}

	const getUserByIdFromStore = (userId: number) => {
		return queryClient.getQueryData<User[]>([usersQueryKey])?.find((u) => u.id === userId)
	}

	return {
		user: user || null,
		users: users || [],
		currentUser: queryClient.getQueryData<User>([userQueryKey]),
		loading: isLoadingUser || isLoadingUsers,
		error: errorMessage,
		createUser,
		updateUser,
		getUserByIdFromStore,
		changeMyPassword
	}
}

export default useUsers
