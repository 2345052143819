import CheckboxInput from 'components/elements/Input/CheckboxInput'
import Input from 'components/elements/Input/Input'
import InputDate from 'components/elements/Input/InputDate'
import SelectMunicipalityOptions from 'components/elements/SelectOptions/SelectMunicipalityOptions'
import SelectProvinceOptions from 'components/elements/SelectOptions/SelectProvinceOptions'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useCompany from 'hooks/useCompany'
import useFomento from 'hooks/useFomento'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { FomentoPost, StepFomentoForm } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import { getFormikError } from 'utils/formikUtils.ts'

const StepFomento = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const fomentoState = useFomentoStore()
	const transferState = useTransfersStore()

	const { company } = useCompany()

	const {
		getProvinceCode,
		getMunicipalityCode,
		getProvinceByCodeHelper,
		getMunicipalityByProvinceCodeAndMunicipalityCodeHelper
	} = useFomento()
	const [hasShowDistant, setHasShowDistant] = useState<boolean>(false)

	const formik = useFormik<StepFomentoForm>({
		initialValues: {
			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		onSubmit: (values) => {
			const fomentoForm: FomentoPost = {
				provinceOrigin: getProvinceCode(values.provinceOriginId),
				municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
				provinceDestination: getProvinceCode(values.provinceDestinationId),
				municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
				provinceDistant: getProvinceCode(values.provinceDistantId),
				municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
				destinationFreely: values.destinationFreely,
				addressDistant: values.addressDistant,
				finishedAt: values.finishedAt ? values.finishedAt : undefined
			}
			fomentoState.setActualFomentoCreation(fomentoForm)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.PRICES.toLocaleLowerCase()}`)
		}
	})

	useEffect(() => {
		const hasShow = hasToShowDistante()
		setHasShowDistant(hasShow)
	}, [
		formik.values['municipalityOriginId'],
		formik.values['municipalityDestinationId'],
		formik.values['provinceOriginId'],
		formik.values['provinceDestinationId']
	])

	useEffect(() => {
		if (!fomentoState.actualFomentoCreation) return
		if (!company) return
		const provinceOriginId = fomentoState.actualFomentoCreation.provinceOrigin
			? getProvinceByCodeHelper(fomentoState.actualFomentoCreation.provinceOrigin)
			: getProvinceByCodeHelper(company.province)

		formik.setFieldValue('provinceOriginId', provinceOriginId)
		formik.setFieldValue(
			'municipalityOriginId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoState.actualFomentoCreation.provinceOrigin,
				fomentoState.actualFomentoCreation.municipalityOrigin
			)
		)

		const provinceDestinationId = fomentoState.actualFomentoCreation.provinceDestination
			? getProvinceByCodeHelper(fomentoState.actualFomentoCreation.provinceDestination)
			: getProvinceByCodeHelper(company.province)

		formik.setFieldValue('provinceDestinationId', provinceDestinationId)

		formik.setFieldValue(
			'municipalityDestinationId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoState.actualFomentoCreation.provinceDestination,
				fomentoState.actualFomentoCreation.municipalityDestination
			)
		)

		formik.setFieldValue(
			'provinceDistantId',
			getProvinceByCodeHelper(fomentoState.actualFomentoCreation.provinceDistant)
		)

		formik.setFieldValue(
			'municipalityDistantId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoState.actualFomentoCreation.provinceDistant,
				fomentoState.actualFomentoCreation.municipalityDistant
			)
		)
		formik.setFieldValue('destinationFreely', fomentoState.actualFomentoCreation.destinationFreely)
		formik.setFieldValue('addressDistant', fomentoState.actualFomentoCreation.addressDistant)
	}, [fomentoState.actualFomentoCreation, company])

	useEffect(() => {
		if (!transferState.actualTransferCreation) return
		formik.setFieldValue('finishedAt', transferState.actualTransferCreation.date)
	}, [transferState.actualTransferCreation])

	const hasToShowDistante = () => {
		if (!formik.values['municipalityOriginId'] || !formik.values['municipalityDestinationId']) {
			return false
		}
		return formik.values['municipalityOriginId'] === formik.values['municipalityDestinationId']
	}

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={4}>
			<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
				<InputDate
					id='finishedAt'
					label={t('transfer.create_steps.step_fomento.selectors.finishedAt.label')}
					value={formik.values['finishedAt'] ? new Date(formik.values['finishedAt']) : undefined}
					onSelect={(date) => formik.setFieldValue('finishedAt', dateToSQL(date))}
					error={getFormikError(formik, 'finishedAt')}
				/>
				<CheckboxInput
					label={t('transfer.create_steps.step_fomento.free_text')}
					id='destinationFreely'
					onChange={(e) => formik.setFieldValue('destinationFreely', e.target.checked)}
					checked={formik.values['destinationFreely']}
					styles={{ margin: '1rem 0' }}
				/>
				<SelectProvinceOptions
					onSelect={(provinceId) => {
						if (provinceId !== formik.values['provinceOriginId']) {
							formik.setFieldValue('provinceOriginId', provinceId)
							formik.setFieldValue('municipalityOriginId', null)
						}
					}}
					id='provinceOriginId'
					selectedId={formik.values['provinceOriginId']}
					error={getFormikError(formik, 'provinceOriginId')}
					label={t('transfer.create_steps.step_fomento.selectors.province.label.origin')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.province.placeholders.origin'
					)}
				/>
				<SelectMunicipalityOptions
					onSelect={(municipalityId) =>
						formik.setFieldValue('municipalityOriginId', municipalityId)
					}
					id='municipalityOriginId'
					selectedId={formik.values['municipalityOriginId']}
					provinceSelected={getProvinceCode(formik.values['provinceOriginId'])}
					error={getFormikError(formik, 'municipalityOriginId')}
					label={t('transfer.create_steps.step_fomento.selectors.municipality.label.origin')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.municipality.placeholders.origin'
					)}
				/>
				<SelectProvinceOptions
					onSelect={(provinceId) => {
						if (provinceId !== formik.values['provinceDestinationId']) {
							formik.setFieldValue('provinceDestinationId', provinceId)
							formik.setFieldValue('municipalityDestinationId', null)
						}
					}}
					id='provinceDestinationId'
					selectedId={formik.values['provinceDestinationId']}
					error={getFormikError(formik, 'provinceDestinationId')}
					label={t('transfer.create_steps.step_fomento.selectors.province.label.destination')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.province.placeholders.destination'
					)}
					isLabelOptional={formik.values['destinationFreely']}
				/>
				<SelectMunicipalityOptions
					onSelect={(municipalityId) =>
						formik.setFieldValue('municipalityDestinationId', municipalityId)
					}
					id='municipalityDestinationId'
					selectedId={formik.values['municipalityDestinationId']}
					provinceSelected={getProvinceCode(formik.values['provinceDestinationId'])}
					error={getFormikError(formik, 'municipalityDestinationId')}
					label={t('transfer.create_steps.step_fomento.selectors.municipality.label.destination')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.municipality.placeholders.destination'
					)}
					isLabelOptional={formik.values['destinationFreely']}
				/>
				{hasShowDistant && (
					<>
						<p>{t('transfer.create_steps.step_fomento.p')}</p>
						<SelectProvinceOptions
							onSelect={(provinceId) => {
								if (provinceId !== formik.values['provinceDistantId']) {
									formik.setFieldValue('provinceDistantId', provinceId)
									formik.setFieldValue('municipalityDistantId', null)
								}
							}}
							id='provinceDistantId'
							selectedId={formik.values['provinceDistantId']}
							error={getFormikError(formik, 'provinceDistantId')}
							label={t('transfer.create_steps.step_fomento.selectors.province.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.province.placeholders.afar'
							)}
						/>
						<SelectMunicipalityOptions
							onSelect={(municipalityId) =>
								formik.setFieldValue('municipalityDistantId', municipalityId)
							}
							id='municipalityDistantId'
							selectedId={formik.values['municipalityDistantId']}
							provinceSelected={getProvinceCode(formik.values['provinceDistantId'])}
							error={getFormikError(formik, 'municipalityDistantId')}
							label={t('transfer.create_steps.step_fomento.selectors.municipality.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.municipality.placeholders.afar'
							)}
						/>
						<Input
							type='text'
							label={t('transfer.create_steps.step_fomento.selectors.address.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.address.placeholders.afar'
							)}
							id='addressDistant'
							onChange={(e) => formik.setFieldValue('addressDistant', e.target.value)}
							className={formik.values['addressDistant'] ? 'selected' : 'not-selected'}
							valueSelected={formik.values['addressDistant']}
							error={getFormikError(formik, 'addressDistant')}
						/>
					</>
				)}
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepFomento
