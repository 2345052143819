import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import useDrivers from 'hooks/useDrivers'
import useProviders from 'hooks/useProviders'

import useUsers from 'hooks/useUser'
import { Driver, DriverAssociations } from 'models/Driver'
import { Provider, ProviderAssociations } from 'models/Provider'
import { UserRoles } from 'models/User'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './AssociationsUser.scss'

export enum ASSOCIATION_TYPES {
	DRIVER = 'DRIVER',
	PROVIDER = 'PROVIDER'
}

interface Props {
	type: keyof typeof ASSOCIATION_TYPES
	resource: Driver | Provider
	onChangeAssociations: (userIds: number[]) => void
}

type Associations = DriverAssociations | ProviderAssociations

const AssociationsUser: React.FC<Props> = ({ type, resource, onChangeAssociations }) => {
	const [selectedUsers, setSelectedUsers] = useState(new Set<number>())
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { users, loading: usersLoading } = useUsers()
	const { getAssociationsByProviderId } = useProviders()
	const { getAssociationsByDriverId } = useDrivers()
	const { t } = useTranslation()

	useEffect(() => {
		if (!resource) return

		if (type === ASSOCIATION_TYPES.DRIVER) {
			getAssociationsByDriver(resource.id)
		}

		if (type === ASSOCIATION_TYPES.PROVIDER) {
			getAssociationsByProvider(resource.id)
		}
	}, [resource])

	const filterAssociationsAndGetUserIds = (
		associations: Associations[],
		id: number,
		idField: 'driverId' | 'providerId'
	) => {
		const filteredAssociations = associations.filter((association) => {
			if (idField === 'driverId') {
				return (association as DriverAssociations).driverId === id
			} else if (idField === 'providerId') {
				return (association as ProviderAssociations).providerId === id
			} else {
				return false
			}
		})
		const userIds = filteredAssociations.map((association) => association.userId)
		return { userIds }
	}

	const getAssociationsByDriver = async (driverId: number) => {
		setIsLoading(true)
		const associations = getAssociationsByDriverId(driverId)
		const { userIds } = filterAssociationsAndGetUserIds(associations, driverId, 'driverId')

		setSelectedUsers(new Set(userIds))
		setIsLoading(false)
	}

	const getAssociationsByProvider = async (providerId: number) => {
		setIsLoading(true)
		const associations = getAssociationsByProviderId(providerId)

		const { userIds } = filterAssociationsAndGetUserIds(associations, providerId, 'providerId')

		setSelectedUsers(new Set(userIds))
		setIsLoading(false)
	}

	const formatAssociations = (): AssociationOption[] => {
		const newAssociations: AssociationOption[] = users
			.filter((user) => {
				if (user.userTypeId !== UserRoles.driver && type === ASSOCIATION_TYPES.DRIVER) return false
				if (user.userTypeId !== UserRoles.provider && type === ASSOCIATION_TYPES.PROVIDER)
					return false
				return true
			})
			.map((user) => {
				const isChecked = selectedUsers.has(user.id)
				return {
					id: user.id,
					name: `${user.name} ${user.lastName}`,
					isSelected: isChecked
				}
			})
		return newAssociations
	}

	const handleSelection = (selected: AssociationOption[]) => {
		const newSelectedUsers = new Set<number>()
		selected.forEach((association) => {
			if (association.isSelected) {
				newSelectedUsers.add(association.id)
			}
		})
		setSelectedUsers(newSelectedUsers)
		onChangeAssociations(Array.from(newSelectedUsers))
	}

	useEffect(() => {
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		onChangeAssociations(Array.from(selectedUsers))
	}, [selectedUsers, users])

	return (
		<div>
			<AssociationList
				isLoading={isLoading || usersLoading}
				associations={associationsOptions}
				title={t('provider.associated_users')}
				addButtonText={t('provider.associate_users')}
				modalContent={{
					title: t('provider.associate_user'),
					subtitle: t('provider.users'),
					description: t('provider.associate_user_description')
				}}
				handleSelection={handleSelection}
			/>
		</div>
	)
}

export default AssociationsUser
