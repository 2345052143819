import StepObservationsInputs from 'components/Transfer/StepInputs/StepObservationsInputs'
import Button from 'components/elements/Button/Button'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { useCreateTransfer } from 'hooks/useCreateTransfer'
import useToast from 'hooks/useToast'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { StepObservationsForm, TransferPost } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'

const StepObservations = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const transferState = useTransfersStore()
	const fomentoState = useFomentoStore()
	const { createTransfer } = useCreateTransfer()
	const { showBackendErrorToast } = useToast()

	const formik = useFormik<StepObservationsForm>({
		initialValues: {
			observations: emptyString
		},
		onSubmit: async (values) => {
			try {
				const transferPost: Partial<TransferPost> = {
					observations: values.observations,
					...transferState.actualTransferCreation
				}
				transferState.updateActualTransferCreation(transferPost)
				const transferCreated = await createTransfer(transferPost)

				const isReadyToFomento = fomentoState.isReadyToSaveInFomento()

				if (isReadyToFomento && transferCreated.id) {
					const fomentoForm = fomentoState.actualFomentoCreation
					fomentoState.createFomento(fomentoForm, transferCreated.id)
				}

				navigate(
					`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.END.toLocaleLowerCase()}/${
						transferCreated.id
					}`
				)
			} catch (error) {
				console.error(error)
				showBackendErrorToast(error)
			}
		}
	})

	return (
		<FormTransferLayout
			step={8}
			footer={
				<Button fullWidth onClick={() => formik.handleSubmit()}>
					{t('general.finish')}
				</Button>
			}>
			<FormSectionLayout
				title={t('transfer.create_steps.step_observations.h1')}
				helper={`(${t('general.optional')})`}>
				<StepObservationsInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepObservations
