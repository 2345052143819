import { BellCheckedIcon, BellCrossIcon, BellFilledIcon, CheckSuccessIcon } from 'assets/svgs'
import { Fomento } from 'models/Fomento'
import { TransferStatus } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './TransferInfoCard.scss'
import colors from 'constants/colors'

interface Status {
	icon: JSX.Element
	title: string
	status: TransferStatus
}

interface Props {
	fomento?: Fomento
}

const TransferInfoCard: FC<Props> = (props) => {
	const { fomento } = props

	const [statusInfo, setStatusInfo] = useState<Status>()
	const { t } = useTranslation()

	const getStatus = () => {
		if (!fomento)
			return {
				icon: <BellFilledIcon width={34} height={34} color={colors.deepBlue} />,
				title: t('transfer.transfer_info.not_communicated'),
				status: TransferStatus.notCommunicated
			}
		if (fomento.confirmedAt)
			return {
				icon: <BellCheckedIcon />,
				title: t('transfer.transfer_info.confirmed'),
				status: TransferStatus.confirmed
			}
		else if (fomento.cancelledAt)
			return {
				icon: <BellCrossIcon />,
				title: t('transfer.transfer_info.communication_canceled'),
				status: TransferStatus.communicationCanceled
			}
		else if (fomento.scheduledAt)
			return {
				icon: <CheckSuccessIcon />,
				title: t('transfer.transfer_info.communicated'),
				status: TransferStatus.communicated
			}
		else
			return {
				icon: <BellFilledIcon width={34} height={34} color={colors.deepBlue} />,
				title: t('transfer.transfer_info.not_communicated'),
				status: TransferStatus.notCommunicated
			}
	}

	useEffect(() => {
		const newStatusInfo = getStatus()
		setStatusInfo(newStatusInfo)
	}, [fomento])

	const classNames = () => {
		let classes = 'transfer-info-card'
		classes += ` ${statusInfo?.status}`

		return classes
	}

	return (
		<div className={classNames()}>
			{statusInfo?.icon}
			<h1>{statusInfo?.title}</h1>
		</div>
	)
}

export default TransferInfoCard
