import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import useVehicles from 'hooks/useVehicles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString, firstElement } from 'utils/common'

const id = 'select-vehicle'

interface Props {
	selectedId?: number | number[] | null
	onSelect: (id: number | number[]) => void
	error?: string
	label: string
	isLabelOptional?: boolean
	isMultiple?: boolean
}

const SelectVehicleOptions: React.FC<Props> = (props) => {
	const { onSelect, selectedId, error, label, isLabelOptional = false, isMultiple = false } = props

	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()

	const { vehicles, loading, error: vehiclesError } = useVehicles()

	const titleAndPlaceholder = t('form.select_at', { field: t('transfer.vehicle') })
	const isMultipleAndArray = isMultiple && Array.isArray(selectedId)

	const handleClick = (ids: number[]) => {
		const selected = isMultiple ? ids : ids[firstElement]
		onSelect(selected)
		toggleModalVisibility()
	}

	const getMultipleValueSelected = () => {
		if (isMultipleAndArray && selectedId.length) {
			const selectedVehicles = selectedId.map(
				(id) => vehicles.find((vehicle) => vehicle.id === id)?.plate
			)
			return selectedVehicles.join(', ')
		}
	}

	const getValueSelected = () => {
		if (selectedId && !Array.isArray(selectedId)) {
			const vehicle = vehicles.find((vehicle) => vehicle.id === selectedId)
			return vehicle?.plate
		}
	}

	const itemsDropdownModal = vehicles
		.filter((vehicle) => {
			if (vehicle.id === selectedId) return true
			return vehicle.isActive
		})
		.map((vehicle) => {
			return {
				id: vehicle.id,
				name: vehicle.plate,
				isSelected: isMultipleAndArray ? selectedId?.includes(vehicle.id) : undefined,
				isDisabled: vehicle.id === selectedId && !vehicle.isActive
			}
		})

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				id={id}
				type='select'
				text={isMultiple ? getMultipleValueSelected() : getValueSelected()}
				placeholder={titleAndPlaceholder}
				onClick={toggleModalVisibility}
				error={error}
				disabled={loading}
			/>
			<DropdownSelectorModal
				items={itemsDropdownModal}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				title={t('form.select_at', { field: t('transfer.vehicle') })}
				visible={modalIsVisible}
				isMultiple={isMultiple}
			/>
			{error && <span className='input-error-message'>{error}</span>}
			{vehiclesError && <span className='input-error-message'>{vehiclesError}</span>}
		</div>
	)
}

export default SelectVehicleOptions
