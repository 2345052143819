import DriverInputs from 'components/Resources/Driver/DriverInputs'
import ButtonComponent from 'components/elements/Button/Button'
import StepNumber from 'components/elements/StepNumber/StepNumber'
import { ONBOARDING_CREATE_FIRST_VEHICLE_URL, ONBOARDING_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useDrivers from 'hooks/useDrivers'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { DriverForm, DriverPost } from 'models/Driver'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { emptyString, zeroAmount } from 'utils/common'
import * as Yup from 'yup'
import { ONBOARDING_FORM_PROGRESS, ONBOARDING_STEP } from './FirstSteps'
import './FirstSteps.scss'
import FirstStepsCounter from './FirstStepsCounter'

const CreateFirstDriver: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { drivers, createDriver } = useDrivers()
	const [loading, setLoading] = useState(false)

	const formik = useFormik<DriverForm>({
		initialValues: {
			name: emptyString,
			email: emptyString,
			changeTransferEmail: true,
			communicationEmail: true,
			confirmationEmail: true,
			isActive: true,
			profit: undefined
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('driver.name') })),
			email: Yup.string().email(t('errors.mail_not_valid'))
		}),
		onSubmit: async (values) => {
			const driver: DriverPost = {
				name: values.name,
				email: values.email,
				changeTransferEmail: values.changeTransferEmail,
				communicationEmail: values.communicationEmail,
				confirmationEmail: values.confirmationEmail,
				isActive: values.isActive,
				profit: values.profit ?? zeroAmount
			}

			try {
				setLoading(true)
				await createDriver(driver)
				toast.success(t('driver.success_create'))
				navigate(ONBOARDING_CREATE_FIRST_VEHICLE_URL)
			} catch (error) {
				toast.error(t('driver.error_create'))
			} finally {
				setLoading(false)
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}
	useEffect(() => {
		const isComplete = drivers.length

		if (isComplete) {
			navigate(ONBOARDING_URL)
		}
	}, [drivers])

	return (
		<FormLayout
			title={t('onboarding.first_steps.form_title')}
			progress={ONBOARDING_FORM_PROGRESS[ONBOARDING_STEP.THIRD]}
			leftButton={
				<ButtonComponent variant='text' onClick={() => navigate(ONBOARDING_URL)}>
					{t('general.quit')}
				</ButtonComponent>
			}
			rightButton={<FirstStepsCounter step={ONBOARDING_STEP.THIRD} />}
			footer={
				<div className='first-steps-footer'>
					<ButtonComponent onClick={handleSubmit} fullWidth loading={loading}>
						{t('general.save_and_continue')}
					</ButtonComponent>
				</div>
			}>
			<FormSectionLayout
				title={t('onboarding.first_steps.create_first_driver')}
				titleIcon={<StepNumber number={ONBOARDING_STEP.THIRD} size='large' />}
				noDivider>
				<p className='first-steps-form-description'>
					{t('onboarding.first_steps.create_first_driver_description')}
				</p>
			</FormSectionLayout>
			<DriverInputs formik={formik} setFieldRef={setFieldRef} />
		</FormLayout>
	)
}

export default CreateFirstDriver
