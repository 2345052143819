import { useQuery } from '@tanstack/react-query'
import { dailyTransfersQueryKey } from 'constants/reactQueryKeys'
import { SharedTransfer, Transfer } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFomentoByDateService } from 'services/fomento'
import { getSharedTransfersByDateService, getTransfersByDateService } from 'services/transfers'
import { getStartEndTransfersByDateService } from 'services/transfersStartEnd'
import { emptyString } from 'utils/common'
import { dateToSQL, dateToSpanishToTimestamp } from 'utils/dateUtils'

const useTransfers = (date: Date) => {
	const [transfersOrdered, setTransfersOrdered] = useState<Transfer[]>([])
	const [transfersExcluded, setTransfersExcluded] = useState<Transfer[]>([])
	const [allTransfers, setAllTransfers] = useState<Transfer[]>([])
	const [pendingTransfers, setPendingTransfers] = useState<SharedTransfer[]>([])
	const dateString = dateToSQL(date)
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const { t } = useTranslation()

	// Fetch transfers by date
	const {
		data: transfers,
		isLoading: isLoadingTransfers,
		error: transfersError,
		isError: isErrorTransfers,
		refetch: refetchTransfers
	} = useQuery({
		queryKey: [dailyTransfersQueryKey, dateString],
		queryFn: () => getTransfersByDateService(dateString)
	})

	// Fetch fomento by date
	const {
		// data: fomento,
		isLoading: isLoadingFomento,
		error: fomentoError,
		refetch: refetchFomento,
		isError: isErrorFomento
	} = useQuery({
		queryKey: ['fomento', dateString],
		queryFn: () => getFomentoByDateService(dateString)
	})

	// Fetch shared transfers by date
	const {
		data: sharedTransfers,
		isLoading: isLoadingSharedTransfers,
		error: sharedTransfersError,
		isError: isErrorSharedTransfers,
		refetch: refetchSharedTransfers
	} = useQuery({
		queryKey: ['sharedTransfers', dateString],
		queryFn: () => getSharedTransfersByDateService(dateString)
	})

	// Fetch start/end transfers by date
	const {
		// data: startEndTransfers,
		isLoading: isLoadingStartEndTransfers,
		error: startEndTransfersError,
		isError: isErrorStartEndTransfers,
		refetch: refetchStartEndTransfers
	} = useQuery({
		queryKey: ['startEndTransfers', dateString],
		queryFn: () => getStartEndTransfersByDateService(dateString)
	})

	useEffect(() => {
		if (isErrorTransfers && transfersError) {
			setErrorMessage(t('transfer.error_load_transfers'))
		}
		if (isErrorFomento && fomentoError) {
			setErrorMessage(t('transfer.error_load_fomento'))
		}
		if (isErrorSharedTransfers && sharedTransfersError) {
			setErrorMessage(t('transfer.error_load_shared_transfers'))
		}
		if (isErrorStartEndTransfers && startEndTransfersError) {
			setErrorMessage(t('transfer.error_load_start_end_transfers'))
		}
	}, [
		isErrorTransfers,
		transfersError,
		isErrorFomento,
		fomentoError,
		isErrorSharedTransfers,
		sharedTransfersError,
		isErrorStartEndTransfers,
		startEndTransfersError,
		t
	])

	const fetchAll = () => {
		refetchTransfers()
		refetchFomento()
		refetchSharedTransfers()
		refetchStartEndTransfers()
	}

	useEffect(() => {
		if (transfers) {
			setAllTransfers(transfers)
			filterTransfersByCurrentDateAndOrder()
		}
	}, [transfers])

	useEffect(() => {
		if (sharedTransfers) {
			setPendingTransfers(sharedTransfers)
		}
	}, [sharedTransfers])

	const filterTransfersByCurrentDateAndOrder = () => {
		console.log('filterTransfersByCurrentDateAndOrder', allTransfers)
		const orderedTransfers = allTransfers
			.filter((transfer) => transfer.date === dateString)
			.sort(
				(a, b) =>
					dateToSpanishToTimestamp(new Date(`${a.date} ${a.time}`)) -
					dateToSpanishToTimestamp(new Date(`${b.date} ${b.time}`))
			)
		setTransfersOrdered(orderedTransfers)
		setTransfersExcluded([])
	}

	const onSearch = (searchTerm: string) => {
		if (searchTerm === emptyString) {
			setTransfersOrdered(allTransfers)
			return
		}
		const searchedTransfers = allTransfers.filter((transfer) => {
			return (
				transfer.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.originAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.destinationAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.email?.toLowerCase().includes(searchTerm.toLowerCase())
			)
		})
		setTransfersOrdered(searchedTransfers)
	}

	const filterNextTransfers = () => {
		const now = new Date()
		const filteredTransfers = transfersOrdered.filter((transfer) => {
			const transferDate = new Date(`${transfer.date} ${transfer.time}`)
			return transferDate.getTime() >= now.getTime()
		})
		setTransfersOrdered(filteredTransfers)

		const excludedTransfers = transfersOrdered.filter((transfer) => {
			return !filteredTransfers.includes(transfer)
		})
		setTransfersExcluded(excludedTransfers)
	}

	return {
		transfers: transfersOrdered,
		transfersExcluded,
		allTransfers,
		onSearch,
		filterTransfersByCurrentDateAndOrder,
		filterNextTransfers,
		pendingTransfers,
		loading:
			isLoadingTransfers ||
			isLoadingFomento ||
			isLoadingSharedTransfers ||
			isLoadingStartEndTransfers,
		error: errorMessage,
		onRefresh: fetchAll
	}
}

export default useTransfers
