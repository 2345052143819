import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import useUsers from 'hooks/useUser'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString, firstElement } from 'utils/common'

const id = 'select-user'

interface Props {
	selectedId: number | number[] | null
	onSelect: (id: number | number[]) => void
	error?: string
	label: string
	isLabelOptional?: boolean
	isMultiple?: boolean
}

const SelectUserOptions: FC<Props> = (props) => {
	const { onSelect, selectedId, error, label, isLabelOptional = false, isMultiple = false } = props
	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const { users, loading, error: usersError } = useUsers()

	const titleAndPlaceholder = t('form.select_at', { field: t('general.user') })
	const isMultipleAndArray = isMultiple && Array.isArray(selectedId)

	const getMultipleValueSelected = () => {
		if (isMultipleAndArray && selectedId.length) {
			const selectedUsers = selectedId.map((id) => users.find((user) => user.id === id)?.name)
			return selectedUsers.join(', ')
		}
	}

	const getValueSelected = () => {
		if (selectedId && !Array.isArray(selectedId)) {
			const user = users.find((user) => user.id === selectedId)
			return user?.name
		}
	}

	const handleClick = (ids: number[]) => {
		const selected = isMultiple ? ids : ids[firstElement]
		onSelect(selected)
		toggleModalVisibility()
	}

	const itemsDropdownModal = users
		.filter((user) => {
			if (user.id === selectedId) return true
			return user.isActive
		})
		.map((user) => {
			return {
				id: user.id,
				name: user.name,
				isSelected: isMultipleAndArray ? selectedId?.includes(user.id) : undefined
			}
		})

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				id={id}
				type='select'
				text={isMultiple ? getMultipleValueSelected() : getValueSelected()}
				placeholder={titleAndPlaceholder}
				onClick={toggleModalVisibility}
				error={error}
				disabled={loading}
			/>
			<DropdownSelectorModal
				title={titleAndPlaceholder}
				items={itemsDropdownModal}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				visible={modalIsVisible}
				isMultiple={isMultiple}
			/>
			{error && <span className='input-error-message'>{error}</span>}
			{usersError && <span className='input-error-message'>{t('user.error_load_users')}</span>}
		</div>
	)
}

export default SelectUserOptions
