import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { vehicleQueryKey, vehiclesQueryKey } from 'constants/reactQueryKeys'
import { VehiclePost, VehicleTechinicalPost } from 'models/Vehicle'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	createVehicleService,
	deleteVehicleService,
	getAllVehiclesService,
	getVehicleById,
	updateVehicleService,
	updateVehicleTechnicalService
} from 'services/vehicles'

const useVehicles = (vehicleId?: number) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	//GET VEHICLE
	const {
		data: vehicle,
		isLoading: isLoadingVehicle,
		error: vehicleError,
		isError: isErrorVehicle
	} = useQuery({
		queryKey: [vehicleQueryKey, vehicleId],
		queryFn: () => getVehicleById(vehicleId!),
		enabled: !!vehicleId,
		//staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	//GET ALL VEHICLES
	const {
		data: vehicles,
		isLoading: isLoadingVehicles,
		error: vehiclesError,
		isError: isErrorVehicles
	} = useQuery({
		queryKey: [vehiclesQueryKey],
		queryFn: getAllVehiclesService,
		enabled: !vehicleId,
		//staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorVehicles && vehiclesError) {
			setErrorMessage(t('vehicle.vehicles_error'))
		}
		if (isErrorVehicle && vehicleError) {
			setErrorMessage(t('vehicle.vehicle_error'))
		}
	}, [isErrorVehicles, vehiclesError, isErrorVehicle, vehicleError, t])

	const invalidateVehicleQueries = (vehicleId?: number) => {
		if (vehicleId) {
			queryClient.invalidateQueries({
				queryKey: [vehicleQueryKey, vehicleId]
			})
		}
		queryClient.invalidateQueries({
			queryKey: [vehiclesQueryKey]
		})
	}

	// CREATE VEHICLE
	const { mutateAsync: createVehicle } = useMutation({
		mutationFn: createVehicleService,
		onSuccess: () => {
			invalidateVehicleQueries()
		},
		onError: (error: unknown) => {
			setErrorMessage(t('vehicle.error_create'))
			console.error('Error creando vehículo:', error)
		}
	})

	// UPDATE VEHICLE
	const { mutateAsync: updateVehicle } = useMutation({
		mutationFn: (data: { vehicle: Partial<VehiclePost>; vehicleId: number }) =>
			updateVehicleService(data.vehicle, data.vehicleId),
		onSuccess: (_data, variables) => {
			invalidateVehicleQueries(variables.vehicleId)
		},
		onError: (error: unknown) => {
			setErrorMessage(t('vehicle.error_update'))
			console.error('Error actualizando vehículo:', error)
		}
	})

	// UPDATE VEHICLE TECHNICAL
	const { mutateAsync: updateVehicleTechnical } = useMutation({
		mutationFn: (data: { vehicle: VehicleTechinicalPost; vehicleId: number }) =>
			updateVehicleTechnicalService(data.vehicle, data.vehicleId),
		onSuccess: (_data, variables) => {
			invalidateVehicleQueries(variables.vehicleId)
		},
		onError: (error: unknown) => {
			console.error('Error actualizando datos técnicos:', error)
		}
	})

	// DELETE VEHICLE
	const { mutateAsync: removeVehicle } = useMutation({
		mutationFn: deleteVehicleService,
		onSuccess: (_data, vehicleId) => {
			invalidateVehicleQueries(vehicleId)
		},
		onError: () => {
			setErrorMessage(t('vehicle.delete_vehicle_error'))
		}
	})

	const activateVehicle = async (vehicleId: number) => {
		try {
			await updateVehicle({ vehicle: { isActive: true }, vehicleId })
		} catch (e) {
			setErrorMessage(t('vehicle.activate_vehicle_error'))
			console.error(e)
		}
	}

	const deactivateVehicle = async (vehicleId: number) => {
		try {
			await updateVehicle({ vehicle: { isActive: false }, vehicleId })
		} catch (e) {
			setErrorMessage(t('vehicle.deactivate_vehicle_error'))
			console.error(e)
		}
	}

	return {
		vehicle: vehicle || null,
		vehicles: vehicles || [],
		loading: isLoadingVehicle || isLoadingVehicles,
		error: errorMessage,
		createVehicle,
		updateVehicle,
		updateVehicleTechnical,
		removeVehicle,
		activateVehicle,
		deactivateVehicle
	}
}

export default useVehicles
