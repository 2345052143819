import ButtonComponent from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import Loading from 'components/elements/Loading/Loading'
import { PROVIDERS_LIST_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useProviders from 'hooks/useProviders'
import useUsers from 'hooks/useUser'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { UserTypeProviderRegisterPost } from 'models/Provider'
import { UserPost, UserRoles } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { handleErrorResponse } from 'services/customFetch'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'

const UserTypeProviderRegister: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { providerId } = useParams<{
		providerId: string
	}>()
	const providerIdNumber = parseInt(providerId ?? emptyString) || undefined
	const { provider, loading, error, resetAndSetAssociations } = useProviders(providerIdNumber)
	const { createUser } = useUsers()

	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [isProviderLoaded, setIsProviderLoaded] = useState(false)

	const formik = useFormik<UserTypeProviderRegisterPost>({
		initialValues: {
			email: emptyString
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t('errors.mail_not_valid'))
				.required(t('errors.required_m', { field: t('provider.email') }))
		}),
		onSubmit: async (values) => {
			if (!providerId) toast.error(t('provider.provider_not_found'))
			if (!provider) toast.error(t('provider.provider_not_found'))
			if (error) toast.error(t('provider.provider_not_found'))
			if (loading) toast.error(t('provider.provider_not_found'))
			if (!providerId || !provider || error || loading) return

			const user: UserPost = {
				email: values.email,
				name: provider.name,
				lastName: provider.name,
				password: '123456789',
				username: values.email,
				userTypeId: UserRoles.provider,
				sendRecoveryPass: true
			}

			try {
				setLoadingSubmit(true)
				const userCreated = await createUser(user)
				await resetAndSetAssociations(provider.id, [userCreated.id])
				toast.success(t('provider.user_created'))
				navigate(PROVIDERS_LIST_URL)
			} catch (error: unknown) {
				const errorResponse = handleErrorResponse(error)
				if (errorResponse) {
					const messagesText = Object.values(errorResponse.messages).join('\n')
					toast.error(messagesText)
				} else {
					toast.error(t('errors.unexpected_error'))
				}
			} finally {
				setLoadingSubmit(false)
			}
		}
	})

	useEffect(() => {
		if (!provider || isProviderLoaded || provider.email === '') return

		formik.setValues({
			email: provider.email
		})
		setIsProviderLoaded(true)
	}, [provider, isProviderLoaded])

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('provider.register.title')}
			leftButton={
				<ButtonComponent onClick={() => navigate(PROVIDERS_LIST_URL)} variant='only-text'>
					{t('general.cancel')}
				</ButtonComponent>
			}
			footer={
				<ButtonComponent
					type='submit'
					onClick={formik.handleSubmit}
					fullWidth
					loading={loadingSubmit}>
					{t('provider.register.save')}
				</ButtonComponent>
			}>
			<FormSectionLayout>
				<p className='body1'>{t('provider.register.description')}</p>
				<Input
					type='text'
					id='email'
					label={t('provider.register.email')}
					placeholder={t('provider.placeholder.email')}
					valueSelected={formik.values.email}
					onChange={formik.handleChange}
					error={formik.errors.email}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default UserTypeProviderRegister
