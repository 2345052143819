import { useConfigStore } from 'stores/useConfigStore'

const useFomento = () => {
	const configState = useConfigStore()

	const getProvinceCode = (provinceId: number | null) => {
		if (!provinceId) return null
		return configState.provinces.byId[provinceId]?.code
	}

	const getMunicipalityCode = (municipalityId: number | null) => {
		if (!municipalityId) return null
		return configState.municipalities.byId[municipalityId]?.municipalityCode
	}

	const getProvinceByCodeHelper = (provinceCode: string | null) => {
		if (!provinceCode) return null
		return configState.getProvinceByCode(provinceCode)?.id
	}

	const getMunicipalityByProvinceCodeAndMunicipalityCodeHelper = (
		provinceCode: string | null,
		municipalityCode: string | null
	) => {
		if (!provinceCode || !municipalityCode) return null
		return configState.getMunicipalityByProvinceCodeAndMunicipalityCode(
			provinceCode,
			municipalityCode
		)?.id
	}

	return {
		getProvinceCode,
		getMunicipalityCode,
		getProvinceByCodeHelper,
		getMunicipalityByProvinceCodeAndMunicipalityCodeHelper
	}
}

export default useFomento
