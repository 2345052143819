import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	dailyTransfersQueryKey,
	fomentoQueryKey,
	sharedTransfersQueryKey,
	startEndTransfersQueryKey,
	transferQueryKey
} from 'constants/reactQueryKeys'
import useDrivers from 'hooks/useDrivers'
import useProviders from 'hooks/useProviders'
import useUser from 'hooks/useUser'
import useVehicles from 'hooks/useVehicles'
import {
	Fomento,
	SharedTransfer,
	StartEndTransfer,
	TransferPost,
	TypeStartEndTransfer
} from 'models/index'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFomentoByBookingIdService } from 'services/fomento'
import {
	deleteTransferService,
	getTransferDetailsByIdService,
	getTransferSharedByBookingId,
	updateTransferService
} from 'services/transfers'
import {
	endTransferService,
	getStartEndTransfersByTransferIdService,
	startTransferService
} from 'services/transfersStartEnd'
import { firstElement } from 'utils/common'

export const useTransferDetails = (id: number, hasFetchdata?: boolean) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const [fomento, setFomento] = useState<Fomento>()
	const [accepted, setAccepted] = useState<SharedTransfer>()
	const [pendings, setPendings] = useState<SharedTransfer[]>([])
	const [hasStart, setHasStart] = useState<boolean>(false)
	const [hasEnd, setHasEnd] = useState<boolean>(false)
	const [startEndDetails, setStartEndDetails] = useState<StartEndTransfer | null>(null)
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// Fetch transfer details by ID
	const {
		data: transfer,
		refetch: fetchDetails,
		isLoading: isLoadingTransfer,
		error: transferError,
		isError: isErrorTransfer
	} = useQuery({
		queryKey: [transferQueryKey, id],
		queryFn: () => getTransferDetailsByIdService(id),
		enabled: hasFetchdata
	})

	const { provider } = useProviders(transfer?.providerId)
	const { driver } = useDrivers(transfer?.driverId)
	const { vehicle } = useVehicles(transfer?.vehicleId)
	const { user } = useUser(transfer?.employeeId)

	// Fetch Fomento by Booking ID
	const { data: fetchedFomento, refetch: fetchFomentoByBookingId } = useQuery({
		queryKey: [fomentoQueryKey, id],
		queryFn: () => getFomentoByBookingIdService(id),
		enabled: hasFetchdata
	})

	// Fetch shared transfers by Booking ID
	const { data: sharedTransfers, refetch: fetchSharedTransfersByBookingId } = useQuery({
		queryKey: [sharedTransfersQueryKey, id],
		queryFn: () => getTransferSharedByBookingId(id),
		enabled: hasFetchdata
	})

	// Fetch start/end transfers by Booking ID
	const { data: startEndTransfers, refetch: fetchStartEndTransfersByBookingId } = useQuery({
		queryKey: [startEndTransfersQueryKey, id],
		queryFn: () => getStartEndTransfersByTransferIdService(id),
		enabled: hasFetchdata
	})

	// Fetch all necessary data when hasFetchData is true
	const fetchAll = () => {
		fetchFomentoByBookingId()
		fetchSharedTransfersByBookingId()
		fetchDetails()
		fetchStartEndTransfersByBookingId()
	}

	useEffect(() => {
		if (hasFetchdata) {
			fetchAll()
		}
	}, [id])

	useEffect(() => {
		if (sharedTransfers) {
			getSharedInfo(sharedTransfers)
		}
	}, [sharedTransfers])

	useEffect(() => {
		if (startEndTransfers) {
			getStartEndInfo(startEndTransfers)
		}
	}, [startEndTransfers])

	useEffect(() => {
		if (fetchedFomento) {
			setFomento(fetchedFomento)
		}
	}, [fetchedFomento])

	useEffect(() => {
		if (isErrorTransfer && transferError) {
			setErrorMessage(t('transfer.error_load_transfer'))
		}
	}, [isErrorTransfer, transferError, t])

	const getSharedInfo = (sharedTransfers: SharedTransfer[]) => {
		const acceptedTransfer = sharedTransfers.find((transfer) => transfer.acceptedTime !== null)
		if (acceptedTransfer) {
			setAccepted(acceptedTransfer)
		}

		const pendingTransfers = sharedTransfers.filter(
			(transfer) => transfer.acceptedTime === null && transfer.rejectedTime === null
		)
		setPendings(pendingTransfers)
	}

	const getStartEndInfo = (startEndTransfers: StartEndTransfer[]) => {
		console.log({ startEndTransfers })
		const startEndByDriverId = [] as StartEndTransfer[] //todo, fix this
		// const startEndByDriverId = startEndTransfers.filter((transfer) => {
		// 	if (isAdmin) {
		// 		return transfer.driverId === currentUser?.driverId
		// 	}
		// 	return transfer.userId === currentUser?.id && transfer.driverId === currentUser?.driverId
		// })

		if (startEndByDriverId.length) {
			const firstTransfer = startEndByDriverId[firstElement]
			setStartEndDetails(firstTransfer)
			setHasStart(firstTransfer.type === TypeStartEndTransfer.start)
			setHasEnd(firstTransfer.type === TypeStartEndTransfer.end)
		}
	}

	//START SERVICE
	const { mutateAsync: startTransfer } = useMutation({
		mutationFn: startTransferService,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [startEndTransfersQueryKey, id]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.error_start'))
		}
	})

	//END SERVICE
	const { mutateAsync: endTransfer } = useMutation({
		mutationFn: endTransferService,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [startEndTransfersQueryKey, id]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.error_end'))
		}
	})

	//UPDATE TRANSFER
	const { mutateAsync: updateTransfer } = useMutation({
		mutationFn: (data: { transfer: Partial<TransferPost>; id: number }) =>
			updateTransferService(data.id, data.transfer),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [transferQueryKey, id]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.error_update'))
		}
	})

	// DELETE TRANSFER
	const { mutateAsync: deleteTransfer } = useMutation({
		mutationFn: deleteTransferService,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [transferQueryKey, id]
			})
			queryClient.invalidateQueries({
				queryKey: [dailyTransfersQueryKey]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.delete_transfer_error'))
		}
	})

	return {
		transfer,
		provider,
		driver,
		vehicle,
		user,
		fomento,
		accepted,
		pendings,
		hasStart,
		hasEnd,
		startEndDetails,
		onRefresh: fetchAll,
		error: errorMessage,
		loading: isLoadingTransfer,
		updateTransfer,
		deleteTransfer,
		startTransfer,
		endTransfer
	}
}
