export interface Transfer {
	id: number
	statusId: number
	companyId: number
	employeeId: number
	providerId: number
	driverId: number
	vehicleId: number
	vehicleTypeId: number
	date: string
	time: string
	clientName: string
	passengers: number
	route: keyof typeof RouteTypeName
	flight: string
	originAddress: string
	destinationAddress: string
	price: number | null
	vat: number
	toll: number
	paymentMethodId: number
	commission: number
	otherCommission: number
	observations: string
	externalObservations: string
	privateObservations: string
	phone: string
	email: string
	babySeats: number
	boosterSeats: number
	maxicosiSeats: number
	document: string
	isPaid: boolean
	isInvoiced: boolean
	isTaximeter: boolean
	registrationDate: string
	reviewDate: string
	code: string
	externalId: string
	externalCompanyId: number
	driverPrice: number
}

export interface TransferPost {
	statusId?: number //TODO, check if change to ENUM
	providerId: number
	driverId: number
	vehicleId: number | null
	date: string
	time: string
	clientName: string
	passengers: number
	route: keyof typeof RouteTypeName
	flight: string
	originAddress: string
	destinationAddress: string
	price: number
	toll: number
	paymentMethodId: number //TODO check if change to ENUM
	commission: number
	otherCommission: number
	observations: string
	phone: string
	email: string
	babySeats: number
	boosterSeats: number
	maxicosiSeats: number
	isPaid: boolean
	isInvoiced: boolean // ???
	isTaximeter: boolean
	externalCompanyId: number
}

export interface FomentoPost {
	provinceOrigin: string | null
	municipalityOrigin: string | null
	provinceDestination: string | null
	municipalityDestination: string | null
	finishedAt: string | undefined
	provinceDistant: string | null
	municipalityDistant: string | null
	destinationFreely: boolean
	addressDistant: string
}

export interface FomentoInfo extends FomentoPost {
	id: number
	transferId: number
}

export interface RouteType {
	id: number
	name: keyof typeof RouteTypeName
}

export enum RouteTypeName {
	departure = 'departure',
	arrival = 'arrival',
	transfer = 'transfer',
	disposition = 'disposition',
	crane = 'crane'
}

export interface SharedTransferRequest {
	id: number
	bookingId: number
	sendingCompanyId: number
	receivingCompanyId: number
	acceptedBookingId: number | null
	acceptedTime: string | null
	rejectedTime: string | null
	createdAt: string
}

export interface SharedTransfer extends SharedTransferRequest {
	sendingCompanyName: string
	receivingCompanyName: string
	bookingDate: string
}
export interface TransferReceived {
	date: string
	time: string
	route: keyof typeof RouteTypeName
	passengers: number
	originAddress?: string
	destinationAddress?: string
	price?: number
	observations?: string
}

export enum TypeStartEndTransfer {
	start = 'start',
	end = 'end'
}

export interface StartEndTransfer {
	id: number
	bookingId: number
	companyId: number
	userId: number
	type: keyof typeof TypeStartEndTransfer
	createdAt: string
	finishedAt: string | null
	bookingDate: string
	driverId: number
}

export enum ExtraTypes {
	babySeats = 'babySeats',
	boosterSeats = 'boosterSeats',
	maxicosiSeats = 'maxicosiSeats'
}

type ExtraTranslationKeys =
	| 'transfer.add_booster_seat'
	| 'transfer.add_baby_seat'
	| 'transfer.add_maxicosi_seat'

export interface Extra {
	id: number
	name: ExtraTranslationKeys
	key: keyof typeof ExtraTypes
	icon: JSX.Element
}

//FORMS
export type FormField = keyof StepDetailsForm | keyof StepAddressesForm // 'FormValues' es tu tipo de formulario

export type AllStepForms = StepRouteForm &
	StepDetailsForm &
	StepAddressesForm &
	StepPaymentForm &
	StepClientForm &
	StepExtrasForm &
	StepObservationsForm

export interface StepRouteForm {
	routeTypeId: number | null
}

export interface StepDetailsForm {
	driverId?: number | null
	providerId: number | null
	vehicleId: number | null
	passengers: number
}

export interface StepAddressesForm {
	date: Date | undefined
	time: string | undefined
	flight: string
	originAddress: string
	destinationAddress: string
}

export interface AllStepFormsWithFomentoForm extends AllStepForms, StepFomentoForm {}

/**
 * A TypeScript utility type that transforms the keys of the given type `T` by appending 'Id' to them and sets their values as optional number or null.
 * It also includes the keys specified by `K` with their original names and types.
 *
 * @template T The original type whose keys are to be transformed.
 * @template K The keys of `T` that should be included in the resulting type with their original names and types.
 *
 * @example
 * // If we have an original type like this:
 * type Original = {
 *   key1: string;
 *   key2: number;
 * };
 *
 * // And we specify 'key1' as the key to be included:
 * type IncludedKeys = 'key1';
 *
 * // Using `WithId<Original, IncludedKeys>` will give us a new type like this:
 * type Transformed = {
 *   key1: string;
 *   key2Id?: number | null;
 * };
 */
type WithId<T, K extends keyof T> = {
	[P in Exclude<keyof T, K> as `${string & P}Id`]: number | null
} & Pick<T, K>

type ExcludeKeys = 'destinationFreely' | 'addressDistant' | 'finishedAt'
export type StepFomentoForm = WithId<FomentoPost, ExcludeKeys>

export interface StepPaymentForm {
	price: number | null
	isPaid: boolean
	commission: number | null
	toll: number | null
}

export interface StepClientForm {
	clientName: string
	phone: string
	email: string
}

export interface StepExtrasForm {
	babySeats: number
	boosterSeats: number
	maxicosiSeats: number
}

export interface StepObservationsForm {
	observations: string
}

export interface TransferFilter {
	startDate: string
	endDate: string
	driverIds?: number[] | null
	providerIds?: number[] | null
	vehicleIds?: number[] | null
	userIds?: number[] | null
	isPaid?: boolean
}
export interface TransferFilterForm {
	driverIds?: number[] | null
	providerIds?: number[] | null
	isPaid?: boolean | undefined
	vehicleIds?: number[] | null
	userIds?: number[] | null
}
export interface Historical {
	totalToll: number
	totalPrice: number
	totalCommission: number
	transfers: Transfer[]
	hidePrice: boolean
}

export const enum TransferStatus {
	notCommunicated = 'not-communicated',
	communicated = 'communicated',
	confirmed = 'confirmed',
	communicationCanceled = 'communication-canceled'
}

export type TranslationKeys =
	| 'transfer.route.departure'
	| 'transfer.route.arrival'
	| 'transfer.route.transfer'
	| 'transfer.route.disposition'
	| 'transfer.route.crane'
