import { useConfigStore, useFriendsStore, useUserStore } from 'stores/index'
import { useLoadingStore } from 'stores/useLoadingStore'

const useInitializeAppData = () => {
	// const [isLoading, setIsLoading] = useState(false)
	const userState = useUserStore()
	const configState = useConfigStore()
	const friendState = useFriendsStore()
	const loadingState = useLoadingStore()

	const getAllData = async () => {
		try {
			loadingState.setLoading(true)
			await Promise.all([
				configState.fetchAllVehicleClasses(),
				configState.fetchDocumentTypes(),
				configState.fetchAllRoles(),
				configState.fetchAllRouteTypes()
			])
			await Promise.all([userState.fetchUsers()])
			await Promise.all([configState.fetchAllProvinces(), configState.fetchAllMunicipalities()])
			await Promise.all([
				friendState.fetchFriends(),
				friendState.fetchFriendRequestsSent(),
				friendState.fetchFriendRequestsReceived()
			])
		} catch (error) {
			console.error(error)
		} finally {
			loadingState.setLoading(false)
		}
	}

	return { getAllData, isLoading: loadingState.isLoading }
}

export default useInitializeAppData
