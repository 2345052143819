import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	driverAssociationsQueryKey,
	driverQueryKey,
	driversQueryKey
} from 'constants/reactQueryKeys'
import { Driver, DriverPost } from 'models/Driver'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	createDriverService,
	deleteAssociationsByDriverId,
	deleteDriverById,
	getAllDriversService,
	getDriverAssociations,
	getDriverById,
	resetAndSetAssociationsToDriver,
	updateDriverService
} from 'services/drivers'
import { createDriverAssociationsByUserService } from 'services/user'

const useDrivers = (driverId?: number) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET SINGLE DRIVER
	const {
		data: driver,
		isLoading: isLoadingDriver,
		error: driverError,
		isError: isErrorDriver,
		isSuccess: isSuccessDriver
	} = useQuery({
		queryKey: [driverQueryKey, driverId],
		queryFn: () => getDriverById(driverId!),
		enabled: !!driverId,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	// GET ALL DRIVERS
	const {
		data: drivers,
		isLoading: isLoadingDrivers,
		error: driversError,
		isError: isErrorDrivers,
		isSuccess: isSuccessDrivers
	} = useQuery({
		queryKey: [driversQueryKey],
		queryFn: getAllDriversService,
		enabled: !driverId,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	// GET DRIVER ASSOCIATIONS
	const {
		data: driverAssociations,
		isLoading: isLoadingAssociations,
		error: associationsError,
		isError: isErrorAssociations
	} = useQuery({
		queryKey: [driverAssociationsQueryKey],
		queryFn: getDriverAssociations,
		// staleTime: 1000 * 60 * 5, //revisar esto cuando separe el useDrivers de useDriver
		refetchOnWindowFocus: true,
		enabled: isSuccessDriver || isSuccessDrivers
	})

	useEffect(() => {
		if (isErrorDrivers && driversError) {
			setErrorMessage(t('driver.error_load_drivers'))
		}
		if (isErrorDriver && driverError) {
			setErrorMessage(t('driver.error_load_driver'))
		}
		if (isErrorAssociations && associationsError) {
			setErrorMessage(t('driver.error_load_associations'))
		}
	}, [
		isErrorDrivers,
		driversError,
		isErrorDriver,
		driverError,
		isErrorAssociations,
		associationsError,
		t
	])

	// Invalidate Queries
	const invalidateDriverQueries = (driverId?: number) => {
		if (driverId) {
			queryClient.invalidateQueries({
				queryKey: [driverQueryKey, driverId]
			})
		}
		queryClient.invalidateQueries({
			queryKey: [driversQueryKey]
		})
		queryClient.invalidateQueries({
			queryKey: [driverAssociationsQueryKey]
		})
	}

	// CREATE DRIVER
	const { mutateAsync: createDriver } = useMutation({
		mutationFn: createDriverService,
		onSuccess: () => {
			invalidateDriverQueries()
		},
		onError: (error: unknown) => {
			setErrorMessage(t('driver.error_create'))
			console.error('Error creando conductor:', error)
		}
	})

	// UPDATE DRIVER
	const { mutateAsync: updateDriver } = useMutation({
		mutationFn: (data: { driver: Partial<DriverPost>; driverId: number }) =>
			updateDriverService(data.driver, data.driverId),
		onSuccess: (_data, variables) => {
			invalidateDriverQueries(variables.driverId)
		},
		onError: (error: unknown) => {
			setErrorMessage(t('driver.error_update'))
			console.error('Error actualizando conductor:', error)
		}
	})

	// DELETE DRIVER
	const { mutateAsync: removeDriver } = useMutation({
		mutationFn: deleteDriverById,
		onSuccess: (_data, driverId) => {
			invalidateDriverQueries(driverId)
		},
		onError: () => {
			setErrorMessage(t('driver.delete_driver_error'))
		}
	})

	// Activate Driver
	const activateDriver = async (driverId: number) => {
		try {
			await updateDriver({ driver: { isActive: true }, driverId })
		} catch (e) {
			setErrorMessage(t('driver.activate_driver_error'))
			console.error(e)
		}
	}

	// Deactivate Driver
	const deactivateDriver = async (driverId: number) => {
		try {
			await updateDriver({ driver: { isActive: false }, driverId })
		} catch (e) {
			setErrorMessage(t('driver.deactivate_driver_error'))
			console.error(e)
		}
	}

	const deleteAssociationsByDriver = async (driverId: number) => {
		try {
			await deleteAssociationsByDriverId(driverId)
		} catch (e) {
			setErrorMessage(t('driver.delete_associations_error'))
			console.error(e)
		}
	}

	const resetAndSetAssociations = async (driverId: number, userIds: number[]) => {
		try {
			return await resetAndSetAssociationsToDriver(driverId, userIds)
		} catch (e) {
			setErrorMessage(t('driver.reset_set_associations_error'))
			console.error(e)
		}
	}

	const createAssociationsByUserId = async (userId: number, driverIds: number[]) => {
		try {
			return await createDriverAssociationsByUserService(userId, driverIds)
		} catch (e) {
			setErrorMessage(t('driver.create_associations_error'))
			console.error(e)
		}
	}

	const getAssociationsByUserId = (userId: number) => {
		return driverAssociations?.filter((association) => association.userId === userId) || []
	}

	const getDriverByIdFromStore = (driverId: number) => {
		return queryClient.getQueryData<Driver[]>([driversQueryKey])?.find((d) => d.id === driverId)
	}

	const hasAssociations = (driverId: number) => {
		return driverAssociations?.some((association) => association.driverId === driverId) || false
	}

	const getAssociationsByDriverId = (driverId: number) => {
		return driverAssociations?.filter((association) => association.driverId === driverId) || []
	}

	return {
		driver: driver || null,
		drivers: drivers || [],
		loading: isLoadingDriver || isLoadingDrivers || isLoadingAssociations,
		error: errorMessage,
		hasAssociations,
		createDriver,
		updateDriver,
		removeDriver,
		activateDriver,
		deactivateDriver,
		getAssociationsByUserId,
		getDriverByIdFromStore,
		deleteAssociationsByDriver,
		resetAndSetAssociations,
		getAssociationsByDriverId,
		createAssociationsByUserId
	}
}

export default useDrivers
