import { ENVIRONMENT } from 'constants/environment'
import { User, UserRoles } from 'models/User'
import { normalize } from 'normalizr'
import { userSchema } from 'schemas/userSchema'
import {
	Credentials,
	fetchUsersService,
	getMeService,
	loginService,
	skipTourService
} from 'services/user'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface State {
	users: {
		byId: { [id: number]: User }
		allIds: number[]
	}
	currentUser: User
	logIn: (credentials: Credentials) => Promise<void>
	getMe: (token: string) => Promise<void>
	logOut: () => void
	fetchUsers: () => Promise<User[]>
	isAdmin(): boolean
	resetUsers: () => void
	skipTour: () => void
}

export const useUserStore = create<State>()(
	devtools(
		persist(
			(set, get) => ({
				users: {
					byId: {},
					allIds: []
				},
				currentUser: {} as User,
				logIn: async (credentials) => {
					const userFromResponse = await loginService(credentials)

					set(() => ({
						currentUser: userFromResponse
					}))
				},
				getMe: async (token) => {
					const userFromResponse = await getMeService(token)

					set(() => ({
						currentUser: userFromResponse
					}))
				},
				logOut: () => {
					const domain = window.location.hostname.split('.').slice(-2).join('.')
					document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain};`

					set({ currentUser: {} as User })
				},
				fetchUsers: async () => {
					const users = await fetchUsersService()
					const normalizedData = normalize(users, [userSchema])
					set({
						users: {
							byId: { ...normalizedData.entities.users },
							allIds: normalizedData.result
						}
					})
					return users
				},
				isAdmin: () => {
					const currentUser = get().currentUser
					const rolesAdmins = [UserRoles.admin, UserRoles['super-admin']]
					return rolesAdmins.includes(currentUser.userTypeId as UserRoles)
				},
				resetUsers: () => {
					set({ users: { byId: {}, allIds: [] } })
				},
				skipTour: async () => {
					await skipTourService()
					set((state) => ({
						currentUser: {
							...state.currentUser,
							showTutorial: false
						}
					}))
				}
			}),
			{
				name: 'users store',
				storage: createJSONStorage(() => localStorage)
			}
		),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'users store'
		}
	)
)
