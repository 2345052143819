import { CloseIcon, UserCirclePlusIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { PROVIDERS_LIST_URL, USER_TYPE_PROVIDER_REGISTER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import { useModal } from 'hooks/useModal'
import useProviders from 'hooks/useProviders'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { DocumentType, Provider, ProviderForm, ProviderPost } from 'models/Provider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'
import ProviderInputs from './ProviderInputs'

const ProviderNew = () => {
	const configStore = useConfigStore()

	const { createProvider } = useProviders()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const [providerCreated, setProviderCreated] = useState<Nullable<Provider>>(null)
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const formik = useFormik<ProviderForm>({
		initialValues: {
			name: emptyString,
			fiscalName: emptyString,
			documentTypeId: null,
			document: emptyString,
			provinceId: null,
			cityId: null,
			address: emptyString,
			postalCode: emptyString,
			isActive: true,
			isInternational: false
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('provider.name') }))
		}),
		onSubmit: async (values) => {
			const provinceCode = values.provinceId
				? configStore.provinces.byId[values.provinceId]?.code
				: emptyString
			const cityCode = values.cityId
				? configStore.municipalities.byId[values.cityId]?.municipalityCode
				: emptyString
			const documentType = values.documentTypeId
				? configStore.documentTypes.byId[values.documentTypeId]?.name
				: DocumentType.CIF
			const provider: ProviderPost = {
				name: values.name,
				fiscalName: values.fiscalName,
				documentType: documentType,
				document: values.document,
				province: provinceCode,
				city: cityCode,
				address: values.address,
				postalCode: values.postalCode,
				isActive: values.isActive,
				isInternational: values.isInternational
			}
			try {
				setLoading(true)
				const providerCreated = await createProvider(provider)
				setProviderCreated(providerCreated)
				toast.success(t('provider.success_create'))
				toggleModalVisibility()
			} catch (error) {
				toast.error(t('provider.error_create'))
			} finally {
				setLoading(false)
			}
		}
	})

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	return (
		<FormLayout
			title={t('provider.new_title')}
			rightButton={
				<ButtonComponent
					onClick={() => navigate(PROVIDERS_LIST_URL)}
					variant='only-icon'
					icon={<CloseIcon />}
				/>
			}
			footer={
				<ButtonComponent type='submit' onClick={handleSubmit} fullWidth loading={loading}>
					{t('provider.save_new')}
				</ButtonComponent>
			}>
			<ProviderInputs formik={formik} setFieldRef={setFieldRef} />
			<ModalActions
				icon={<UserCirclePlusIcon />}
				title={t('provider.access_confirmation_modal.title')}
				description={t('provider.access_confirmation_modal.description')}
				primaryButton={{
					text: t('provider.access_confirmation_modal.primary_button'),
					onClick: () => navigate(`${USER_TYPE_PROVIDER_REGISTER_URL}/${providerCreated?.id}`)
				}}
				secondaryButton={{
					text: t('provider.access_confirmation_modal.secondary_button'),
					onClick: () => navigate(PROVIDERS_LIST_URL)
				}}
				isVisible={modalIsVisible}
				onClose={toggleModalVisibility}
			/>
		</FormLayout>
	)
}

export default ProviderNew
